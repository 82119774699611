<template>
        <div class="container-fluid">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div v-if="(id > 0)" class="col-sm-6">
                        <h1>{{ $t('general.empresa') }}</h1>
                    </div>
                    <div v-else class="col-sm-6">
                        <h1>{{ $t('general.nuevaempresa') }}</h1>
                    </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <div class="row">
                    <!-- Ocultar esta columna en la creación -->
                    <div v-if="(id > 0)" class="col-md-2">
                        <div class="card bg-gradient-primary">
                            <div class="card-body box-profile">
                                <h3 class="profile-username text-center">{{ empresa.nombre_empresa }}</h3>
                            </div>
                        </div>
                        <div class="callout callout-info">
                        <h5>{{ $t('general.ultimamodificacion') }}</h5>
                        <p>{{ empresa.fecha_actualiza_empresa }}</p>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div v-if="(id > 0)" class="card card-default card-tabs">
                            <div class="card-header p-1 pt-1">
                                <ul class="nav nav-pills">
                                    <li class="nav-item"><a class="nav-link active" href="#datos_empresa" data-toggle="tab">{{ $t('general.datos') }}</a></li>
                                    <li class="nav-item"><a class="nav-link" href="#correo_empresa" data-toggle="tab">{{ $t('general.correo') }}</a></li>
                                </ul>
                            </div>
                        </div>
                            <br>
                        <div class="tab-content">
                            <div class="active tab-pane" id="datos_empresa">
                                <empresa-datos :id="id" :empresa="empresa" />
                            </div>
                            <div class="tab-pane" id="correo_empresa">
                                <empresa-correo :id="id"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import EmpresaDatos from './datos/EmpresaDatos.vue';
import EmpresaCorreo from './correo/EmpresaCorreo.vue';
export default {
    props: ['id'],
    components: {
        'empresa-datos': EmpresaDatos,
        'empresa-correo': EmpresaCorreo,
    },
    data() {
        return {
            empresa: [],
        }
    },
    methods: {
        async obtenerEmpresa(id) {
            /* get  modulos/pwgsapi/index.php/lineas-facturacion/:id */
            const api = new PwgsApi;
            const datos = await api.get('empresas/' + id);
            this.empresa = datos.datos;
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            let aux = this.empresa.nombre_empresa;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == "/empresas/" + ventanaid) {
                  
                      
                   
                    this.$store.getters.ventanas[i].name = aux;
                    
                    this.$store.getters.ventanas[i].alt ="Empresa: " + aux + " || " + this.empresa.direccion_empresa + " || "+this.empresa.ciudad_empresa;
                }
            }
            /**/
        },
    },
    mounted() {
        this.obtenerEmpresa(this.id);
    },
    watch: {
        id() {
            this.obtenerEmpresa(this.id);
        },
        route() {
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            let aux = this.empresa.nombre_empresa;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == "/empresas/" + ventanaid) {
                    this.$store.getters.ventanas[i].name = aux;
                    this.$store.getters.ventanas[i].alt = "Empresa: " + aux + " || " + this.empresa.direccion_empresa + " || " + this.empresa.ciudad_empresa;
                }
            }
            /**/
        }
    }
}
</script>